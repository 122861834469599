<template>
  <div />
  <div class="connect-wallet">
    <div class="connect-wallet-content">
      <div class="connect-wallet-title">
        BOTB OG Bull Unvaulting
      </div>

      <img
        class="connect-wallet-image"
        src="../assets/img/Bulls/BOTB.png"
        alt="BAP"
      >
      <div class="connect-wallet-buttons">
        <button
          class="connect-wallet-button connect-wallet-button-wallet"
          @click="connect"
        >
          {{
            "Unvault your OG Bulls"
          }}
        </button>
      </div>
      <div connect-wallet-button />
      <div class="connect-wallet-buttons-botb">
        <a
          target="_blank"
          class="connect-wallet-button connect-wallet-button-botb"
          href="https://opensea.io/collection/bullsontheblock"
        >
          <IOpenSea width="30" />
        </a>
        <a
          target="_blank"
          class="connect-wallet-button connect-wallet-button-botb"
          href="https://twitter.com/BullsOnTheBlock"
        >
          <IOTwitter width="30" />
        </a>
        <a
          target="_blank"
          class="connect-wallet-button connect-wallet-button-botb"
          href="https://www.instagram.com/bullsontheblocknft/"
        >
          <IOInstagram
            fill="white"
            width="30"
          />
        </a>
        <a
          target="_blank"
          class="connect-wallet-button connect-wallet-button-botb"
          href="https://discord.com/invite/botb"
        >
          <IODiscord width="30" />
        </a>
      </div>
    </div>
    <!-- <BottomBulls /> -->
  </div>
</template>

<script>
import IOpenSea from "../assets/icons/opensea.svg?inline";
import IOTwitter from "../assets/icons/twitter.svg?inline";
import IOInstagram from "../assets/icons/instagram.svg?inline";
import IODiscord from "../assets/icons/discord.svg?inline";
import { connectWallet } from "@/web3";
export default {

  components: { IOpenSea, IOTwitter, IOInstagram, IODiscord },
  data() {
    return {

    };
  },
  computed: {



  },
  methods: {
    async connect() {
      await connectWallet();
      this.$router.push("/botb/claim");
    },
  },
};
</script>
